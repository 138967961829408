import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import { slice as discountCode } from './discountCode/discountCode.slice'
import { slice as geolocation } from './geolocation/geolocation.slice'
import { slice as pathname } from './pathname/pathname.slice'

const reducer = combineReducers({
  discountCode: discountCode.reducer,
  pathname: pathname.reducer,
  geolocation: geolocation.reducer,
})

export const store = configureStore({
  reducer,
})

export type RootState = ReturnType<typeof reducer>
export type Thunk = ThunkAction<void, RootState, unknown, Action<string>>
export type Dispatch = ThunkDispatch<RootState, unknown, Action<string>>

// eslint-disable-next-line import-x/no-default-export
export default function getStore(): typeof store {
  return store
}

if (typeof window !== 'undefined') {
  ;(window as any).getState = () => store.getState()
}
