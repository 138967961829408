import { _objectAssign, _objectEntries, isClientSide } from '@naturalcycles/js-lib'
import { RegistrationFlow, WebSignupQueryParam } from '@naturalcycles/shared'
import { navigate } from 'gatsby'
import { getAllUtms } from './queryParams'
import { toURL } from './url'

const signupUrl = process.env['GATSBY_SIGNUP_URL']

export function shouldReceiveUtmTags(url: string): boolean {
  let shouldReceive = false
  const internalSubdomains = ['webshop.naturalcycles.com', 'signup.naturalcycles.com']
  try {
    shouldReceive = internalSubdomains.includes(new URL(url).host)
  } catch {}
  return shouldReceive
}

/**
 * TODO [2025-03-14] Refactor to not be negated boolean. E.g `isNaturalCyclesURL`
 */
export function isNotNaturalCyclesURL(url: string): boolean {
  const hasInternalPrefix = ['/', '#'].some(prefix => url.startsWith(prefix))
  if (hasInternalPrefix) {
    return false
  }

  const internalHosts = [
    'naturalcycles.com',
    'www.naturalcycles.com',
    'webshop.naturalcycles.com',
    'signup.naturalcycles.com',
    process.env['GATSBY_SIGNUP_URL']?.replace('https://', ''),
  ]
  if (isClientSide() || process.env['GATSBY_ENV'] === 'test') {
    internalHosts.push(globalThis.location.host)
  }

  const parsedUrl = toURL(url)
  if (!parsedUrl) {
    return false
  }
  const hasInternalHost =
    internalHosts.includes(parsedUrl.host) ||
    url.includes('/onboarding') ||
    url.includes('/account/login')

  return !hasInternalHost
}

export function buildUtmParams(params: URLSearchParams): URLSearchParams {
  const utmTags = _objectEntries(getAllUtms())
  const newParams = new URLSearchParams(params)
  utmTags.forEach(([key, value]) => {
    newParams.set(key, value)
  })
  return newParams
}

export function addUtmParamsToLink(url: string): string {
  if (!shouldReceiveUtmTags(url)) {
    return url
  }
  const [baseUrl, search] = url.split('?')
  const params = new URLSearchParams(search)
  const newParams = buildUtmParams(params)
  return `${baseUrl}${newParams.size ? `?${newParams.toString()}` : ''}`
}

/**
 * Adds flow=3 to signup urls from the meta landing page.
 */
export function addMetaFlowParam(url: string): string {
  const [baseUrl, search] = url.split('?')

  if (!signupUrl || !baseUrl?.includes(signupUrl)) {
    // Not a signup URL
    return url
  }

  const params = new URLSearchParams(search)
  params.set(WebSignupQueryParam.flow, RegistrationFlow.META_LANDING_PAGE.toString())
  return `${baseUrl}${params.size ? `?${params.toString()}` : ''}`
}

/**
 * A wrapper around Gatsby's navigate function that adds UTM tags to the URL.
 * It also is able to handle external URLs, setting window.location.href instead of using Gatsby's navigate.
 *
 * Passing a string as the first argument will navigate to the URL.
 * Passing a number as the first argument will navigate to a relative position in the history stack.
 */
export function ncNavigate(to: string, options?: { state?: any; replace?: boolean }): void

/**
 * A wrapper around Gatsby's navigate function that adds UTM tags to the URL.
 * It also is able to handle external URLs, setting window.location.href instead of using Gatsby's navigate.
 *
 * Passing a string as the first argument will navigate to the URL.
 * Passing a number as the first argument will navigate to a relative position in the history stack.
 */
export function ncNavigate(to: number, options?: undefined): void

export function ncNavigate(
  to: string | number,
  options?: { state?: any; replace?: boolean },
): void {
  if (typeof to === 'number') {
    // Meaning relative navigation in history
    navigate(to)
    return
  }

  to = addUtmParamsToLink(to)
  to = addMetaFlowParam(to)

  const { state } = options || {}
  const isOnThisHost = toURL(to)?.host === globalThis.location.host

  if (!isOnThisHost) {
    if (state) {
      _objectAssign(globalThis.history.state, state)
    }
    globalThis.location.assign(to)
    return
  }

  /**
   * todo: gatsby types for this fn are broken and I can't figure out why
   * The source code clearly shows this is the correct signature, so we cast it to any for now
   *
   * export interface NavigateFn {
   *  (to: string, options?: NavigateOptions<{}>): Promise<void>; // <-- Reach router types that are async, but gatsby voids them
   *  (to: number, options?: undefined): Promise<void>;
   * }
   *
   * export const navigate: (...args: Parameters<NavigateFn>) => void;
   *
   * With the logic here supporting it:
   * https://github.com/gatsbyjs/gatsby/blob/df69f04835410de7b73189d060bf4dc991768973/packages/gatsby/cache-dir/navigation.js#L56-L62
   */
  navigate(to as any, options as any)
}
