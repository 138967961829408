import { StringMap } from '@naturalcycles/js-lib'
import { LANG } from '@naturalcycles/shared'
import enUS from '../../locales/en-US.json'

export enum SupportedLanguage {
  en = 'en',
  es = 'es',
  pt = 'pt',
  sv = 'sv',
}

export enum SupportedLocaleSuffix {
  US = 'US',
  BR = 'BR',
  SE = 'SE',
  CA = 'CA',
}

export enum SupportedLocale {
  enUS = 'en-US',
}

export const supportedLocaleToLANG: Record<SupportedLocale, LANG> = {
  [SupportedLocale.enUS]: LANG.en_US,
}

export enum SupportedUrl {
  enUS = '',
}

export const translations: Record<SupportedLocale, StringMap> = {
  'en-US': enUS,
  // if we haven't specified a translation for a key in en-CA, we'll use the one from en-US
}
