import { nanoidBrowser } from '@naturalcycles/js-lib'
import { locales } from '@src/cnst/locales.cnst'
import { translations } from '@src/cnst/translations.cnst'
import { Header } from '@src/components/header/Header.component'
import { translationContext } from '@src/context/translation.context'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { store } from '@src/store'
import { setPathname } from '@src/store/pathname/pathname.slice'
import { GatsbyBrowser, WrapPageElementBrowserArgs } from 'gatsby'
import type { ReactElement } from 'react'
import { apiFetcher } from './api.service'

class MetaBrowsingService implements GatsbyBrowser {
  onClientEntry(): void {
    mixpanelService.init()
    window.addEventListener('load', async () => {
      mixpanelService.track('LandingPageLoaded', {
        utm_source_web: 'ncweb_init',
      })
    })

    // Allows us to still track the first pageview for consenting first time visitors
    window.addEventListener('CookiebotOnConsentReady', async () => {
      const id = nanoidBrowser()
      const url = window.location.pathname
      const marketingConsent = window.Cookiebot?.consent?.marketing

      void apiFetcher.post('api/v3/analyticsHooks/pageview', {
        json: { id, url, marketingConsent },
      })
    })
  }

  wrapPageElement({ element, props }: WrapPageElementBrowserArgs): ReactElement {
    const path = props.location.pathname
    const part = path.split('/').slice(1)[0]!
    const { locale } = locales.find(l => l.url === part) || locales[0]!
    const stringsMap = translations[locale]

    return (
      <translationContext.Provider value={stringsMap}>
        <Header />
        {element}
      </translationContext.Provider>
    )
  }

  shouldUpdateScroll(): boolean {
    return false
  }

  onRouteUpdate(): void {
    store.dispatch(setPathname(window.location.pathname))
  }
}

export const metaBrowsingService = new MetaBrowsingService()
