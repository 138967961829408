import { BotDetectionService } from '@naturalcycles/js-lib'

export const APP_STORE_URL =
  'https://itunes.apple.com/us/app/natural-cycles-fertility-app/id765535549?mt=8'

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.naturalcycles.cordova&hl='

export const botDetectionService = new BotDetectionService({
  memoizeResults: true,
  treatCDPAsBotReason: true,
})

export function isE2eTest(): boolean {
  return typeof window !== 'undefined' && !!(window as any).IS_E2E_TEST
}
